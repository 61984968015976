<template>
  <div class="container">
    <!-- Blog Posts -->
    <div class="blog-page">
      <div class="row mt-12">
        <!-- Post Content -->
        <div class="col-lg-9 col-md-8 padding-right-30">
          <!-- Blog Post -->
          <div class="blog-post single-post pt-32 px-24 py-10">
            <!-- Img -->
            <img class="post-img" src="images/valorisation.jpg" alt="" />

            <!-- Content -->
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
              >
                Valoriser les terres agricoles pour mettre fin à la faim
                <o:p></o:p
              ></span>
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >&nbsp;</span
              >
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >Il est un fait ou plutôt un paradoxe que l’on ne souligne pas
                assez. Le continent africain possède 65 % des terres arables
                disponibles sur la planète et, en même temps, dépense chaque
                année plus de 50 milliards de dollars en importation de denrées
                alimentaires. En Afrique subsaharienne, le solde net des terres
                exploitables est d'environ 100 millions d'hectares et, en même
                temps, l’autonomie alimentaire est loin d’être atteinte. Ce
                paradoxe est <em>multicausal</em>. Evidemment, la dépendance
                envers les systèmes pluviaux, le manque d’infrastructures
                d’irrigation, les conséquences du changement climatique et le
                manque d’investissements constituent un faisceau de facteurs qui
                ralentissent le développement de l’agriculture africaine.
                <o:p></o:p
              ></span>
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >Mais, il y a un autre facteur qui freine l’agriculture en
                Afrique&nbsp;: l’inexistence de bourse de terres agricoles. Dans
                son rapport <em>Enabling the Business of Agriculture 2017</em>,
                la Banque Mondiale souligne que la difficulté d’accéder à la
                terre agricole est devenue l’une des principales plaintes des
                opérateurs du secteur privé dans un grand nombre d’enquêtes
                auprès des entreprises agricoles menées dans des pays africains.
                Car, en réalité, le paradoxe sus-cité s’explique en grande
                partie par une imperfection du marché des terres agricoles. Ceux
                qui possèdent les terres ne sont pas ceux qui veulent ou peuvent
                les utiliser. Et c’est très exactement, ce à quoi veut mettre
                fin Landsapp à travers son service LandsSearch. <o:p></o:p
              ></span>
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >LandsSearch est un dispositif gratuit de mise en relation
                d’acheteurs, de loueurs et de vendeurs de terres agricoles au
                moyen d’annonces. Il se donne pour ambition de synthétiser les
                informations liées à la disponibilité des terres agricoles en
                Afrique. <o:p></o:p
              ></span>
            </p>
            <p></p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >L’équipe Landsapp compte ainsi participer à la prochaine
                révolution de l’agriculture en Afrique.&nbsp;<o:p></o:p
              ></span>
            </p>
          </div>
          <!-- Blog Post / End -->

          <!-- Post Navigation -->

          <!-- About Author -->
          <div class="about-author">
            <img src="images/1607127239330.jpg" alt="" />
            <div class="about-description">
              <h4>Sophonie Jed KOBOUDE</h4>
              <a href="#">jed.koboude@landsapp.com</a>
              <p>
                is an engineer graduated from CentraleSupélec in Paris and an
                economist graduated from the Conservatoire National des Arts et
                Métiers in Paris. He has worked with several startups in Africa.
                He is passionate about agriculture, artificial intelligence and
                Africa.
              </p>
            </div>
          </div>

          <!-- Related Posts -->

          <!-- Related Posts / End -->

          <div class="margin-top-50"></div>

          <!-- Reviews -->

          <div class="clearfix"></div>

          <!-- Add Comment -->

          <!-- Add Review Box / End -->
        </div>
        <!-- Content / End -->

        <!-- Widgets -->
        <div class="col-lg-3 col-md-4">
          <div class="sidebar right">
            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->
            <div class="widget" style="margin-top: 80px">
              <h3>Popular Posts</h3>
              <ul class="widget-tabs">
                <!-- Post #1 -->
                <li>
                  <div class="widget-content">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails' })"
                        ><img src="images/valorisation.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails' })"
                          >Valoriser les terres agricoles pour mettre fin à la
                          faim
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #2 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails2' })"
                        ><img src="images/valo2.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails2' })"
                          >Valorisez votre terre agricole au meilleur prix grâce
                          à notre mécanisme d'enchères.</a
                        >
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #3 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails3' })"
                        ><img src="images/valo3.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails3' })"
                          >Déterminez le profil cultural de votre terre grâce à
                          l'intelligence artificielle de LandsApp.
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Widget / End-->

            <div class="clearfix"></div>
            <div class="margin-bottom-40"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sidebar / End -->
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
